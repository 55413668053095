body {
  background: url('wave-haikei.svg') no-repeat center center fixed;
  background-size: cover;
  min-height: auto;
}
/* Override loading styles */
.amplify-button[data-loading='true'] {
  opacity: 0.8;
}

/* Override disabled styles */
.amplify-button[disabled='true'] {
  opacity: 0.8;
}

.amplify-heading--1 {
  font-style: bold;
}
.amplify-button {
  background-color: #ec6a2d;
  color: #fff;
}

.amplify-button:hover {
  opacity: 0.8;
  color: #fff;
}

background-customizable {
  color: #fff;
}